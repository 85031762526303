import Rest from "../../../class/tool/Rest"
import Dashboard from "./Dashboard"
import Sale from "../../_operation/Sale/Sale"
import moment from "moment"
import FormBuilder from "../../../class/tool/FormBuilder"
moment.locale("fr")

class DashboardController {
    #_rest = new Rest();
    _baseURL = "dashboard";
    _callback = null;
    _callbackBestCategories = null;
    _callbackBestProducts = null;
    _callbackBestSubcategories = null;
    _callbackCA = null;
    _callbackCategories = null;
    _callbackPayments = null;
    _callbackPricelists = null;
    _callbackProducts = null;
    _callbackSales = null;
    _callbackSalesStats = null;
    _callbackSubcategories = null;
    _callbackVats = null;
    _dateFormat = "DD-MM-YYYY";

    buildDateBounds(dates, toObject = false) {
        const env = JSON.parse(localStorage.getItem('env'));
        let createdDate = moment();
        let params = toObject ? {} : ""

        switch (env.type) {
            case "company":
                for (let i in env.stores) {
                    if (moment(env.stores[i].created_at).isBefore(createdDate)) {
                        createdDate = moment(env.stores[i].created_at);
                    }
                }

                break;
            case "store":
                createdDate = moment(env.created_at);
                break;
            default: break;
        }

        if (toObject) {
            params.begin = moment(dates.dates.begin).format(this._dateFormat)
            params.end = moment(dates.dates.end).format(this._dateFormat)
        }
        else {
            params = "?period[begin]=" + moment(dates.dates.begin).format(this._dateFormat) + "&period[end]=" + moment(dates.dates.end).format(this._dateFormat);

            let versus = {
                begin: moment(dates.dates.begin),
                end: moment(dates.dates.end)
            };

            switch (dates.dateType) {
                case "day":
                    if (moment(versus.begin).subtract(1, "years").isAfter(createdDate)) {
                        versus.begin.subtract(1, "years");
                        versus.end.subtract(1, "years");
                    }
                    else if (moment(versus.begin).subtract(1, "weeks").isAfter(createdDate)) {
                        versus.begin.subtract(1, "weeks");
                        versus.end.subtract(1, "weeks");
                    }
                    else {
                        versus.begin.subtract(1, "days");
                        versus.end.subtract(1, "days");
                    }

                    break;
                case "month":
                    if (moment(versus.begin).subtract(1, "years").isAfter(createdDate)) {
                        versus.begin.subtract(1, "years");
                        versus.end.subtract(1, "years").endOf("month");
                    }
                    else {
                        versus.begin.subtract(1, "months");
                        versus.end.subtract(1, "months").endOf("month");
                    }

                    break;
                case "quarter":
                    if (moment(versus.begin).subtract(1, "years").isAfter(createdDate)) {
                        versus.begin.subtract(1, "years");
                        versus.end.subtract(1, "years").endOf("month");
                    }
                    else {
                        versus.begin.subtract(1, "quarters");
                        versus.end.subtract(1, "quarters").endOf("month");
                    }

                    break;
                case "year":
                    versus.begin.subtract(1, "years");
                    versus.end.subtract(1, "years");
                    break;
                default: break;
            }

            params += "&versus[begin]=" + versus.begin.format(this._dateFormat) + "&versus[end]=" + versus.end.format(this._dateFormat);
        }

        return params
    }
    buildVersusBounds(dates, toObject = false) {
        let params = toObject ? {} : ""

        if (toObject) {
            params.begin = moment(dates.begin).format(this._dateFormat)
            params.end = moment(dates.end).format(this._dateFormat)
        }
        else {
            params = "?versus[begin]=" + moment(dates.begin).format(this._dateFormat) + "&versus[end]=" + moment(dates.end).format(this._dateFormat);
        }

        return params
    }

    index(dates, compare = null) {
        let params = this.buildDateBounds(dates)

        this.#_rest.sendQuery(
            'GET',
            '/' + this._baseURL + params.replaceAll("/", "-"),
            {},
            this.handleIndex)
    }
    handleIndex = (response, error, status = 200) => {
        const object = !error ? new Dashboard(response.data) : null;
        this._callback(object, error, status);
    }

    get(dashboard, attribute, page = null, nb = null, sorton = null, sortvalue = null, input = null) {
        const callbacks = {
            "bestCategories": this.handleGetBestCategories,
            "bestProducts": this.handleGetBestProducts,
            "bestSubcategories": this.handleGetBestSubcategories,
            "ca": this.handleGetCA,
            "categories": this.handleGetCategories,
            "payments": this.handleGetPayments,
            "pricelists": this.handleGetPricelists,
            "products": this.handleGetProducts,
            "sales": this.handleGetSales,
            "salesStats": this.handleGetSalesStats,
            "subcategories": this.handleGetSubcategories,
            "vats": this.handleGetVats
        };
        const restService = new Rest();
        let path = Reflect.get(dashboard, attribute);

        let explode = decodeURI(path).split('?');
        let datas = {};

        path = explode[0];
        Rest.parse_str(explode[1], datas);

        if (attribute === "sales") {
            if (nb !== null)
                datas.nb = nb;

            if (page !== null)
                datas.page = page;

            if (sorton !== null && sortvalue !== null) {
                datas.sorton = sorton;
                datas.sortvalue = sortvalue;
            }

            if (input !== null && input !== "")
                datas.input = input;
        }

        restService.sendQuery(
            'POST',
            '/' + path,
            datas,
            callbacks[attribute]);
    }
    handleGetBestCategories = (response, error, status = 200) => {
        this._callbackBestCategories(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetBestProducts = (response, error, status = 200) => {
        this._callbackBestProducts(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetBestSubcategories = (response, error, status = 200) => {
        this._callbackBestSubcategories(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetCA = (response, error, status = 200) => {
        this._callbackCA(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetCategories = (response, error, status = 200) => {
        this._callbackCategories(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetPayments = (response, error, status = 200) => {
        this._callbackPayments(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetPricelists = (response, error, status = 200) => {
        this._callbackPricelists(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetProducts = (response, error, status = 200) => {
        this._callbackProducts(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetSales = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;


        if(status === 200) {
            let data

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data
            }
            else if (response.data)
                data = response.data

            for (let item in data)
                listObjects.push(new Sale(data[item]));
        }

        this._callbackSales(listObjects, error, pagination, status);
    }
    handleGetSalesStats = (response, error, status = 200) => {
        this._callbackSalesStats((response.data !== undefined && response.data.stats !== undefined) ? response.data.stats : {}, error, status);
    }
    handleGetSubcategories = (response, error, status = 200) => {
        this._callbackSubcategories(response.data !== undefined ? response.data : {}, error, status);
    }
    handleGetVats = (response, error, status = 200) => {
        this._callbackVats(response.data !== undefined ? response.data : {}, error, status);
    }

    exportPosState(email, dates, emails, formats, consolidated = null) {
        let datas = {}
        datas.period = this.buildDateBounds(dates, true);
        datas.email = email.toLowerCase();
        datas.posState = formats;

        if (consolidated !== null)
            datas.consolidated = consolidated;

        if (emails.length > 0)
            datas.otherEmails = emails.join(',').toLowerCase();

        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/export',
            datas,
            this.handleExport);
    }
    exportSales(email, dates, emails, formats) {
        let datas = {}
        datas.period = this.buildDateBounds(dates, true)
        datas.email = email.toLowerCase();
        datas.sales = formats

        if (emails.length > 0)
            datas.otherEmails = emails.join(',').toLowerCase();

        this.#_rest.sendQuery(
            'POST',
            '/' + this._baseURL + '/export',
            datas,
            this.handleExport)
    }
    handleExport = (response, error, status = 200) => {
        if (this._callback !== null)
            this._callback(response, error, status)
    }
}

export default DashboardController
