import React, {useContext, useEffect, useState} from "react"
import $ from "jquery"
import usePrevious from "../../../../class/tool/usePrevious"
import CloseIcon from "../../../icons/CloseIcon"
import FormScreenObject from "../../../form/screen/FormScreenObject"
import FormKeyObject from "../../../form/screen/FormKeyObject"
import FormKeyboardObject from "../../../form/screen/FormKeyboardObject"
import LinkAccessIcon from "../../../icons/LinkAccessIcon"
import RightIcon from "../../../icons/RightIcon"
import LeftIcon from "../../../icons/LeftIcon"
import CategoryIcon from "../../../icons/CategoryIcon"
import SubCategoryIcon from "../../../icons/SubCategoryIcon"
import ScreenContext from "../../../../context/ScreenContext"
import '../../../../css/page/content/screen/SearchBox.css'

const SearchBox = props => {
    const {type, action, close, returnName} = props
    const {categories, products, pricelists, sellers, paymentMethods, functions, keyboards, listKeys, inEditKey, setInEditKey, keyPress, takeItem, setObjectSheetOpened} = useContext(ScreenContext)
    const [list, setList] = useState([])
    const [listFiltered, setListFiltered] = useState([])
    const [input, setInput] = useState("")
    const [listCatalogs, setListCatalogs] = useState([])
    const [listCategories, setListCategories] = useState([])
    const [catalogId, setCatalogId] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const prevInput = usePrevious(input)
    const catalogs = JSON.parse(localStorage.getItem("catalogs"))
    const listByTypes = ["categories", "products", "pricelists", "sellers", "paymentMethods", "functions"]
    const listByZoneSettings = ["TSettings", "RSettings", "MSettings", "SSettings"]

    const resize = () => {
        let top = $("#searchBox").offset().top
        let negativePx = top + 50 + 25

        if (catalogs.length > 1 && ['categories', 'products', 'pricelists'].includes(type))
            negativePx += 40

        if (type === "products")
            negativePx += 40

        $("#searchBox > .scroller").css({maxHeight: "calc(100vh - " + (negativePx + 50) + "px)"})
        $("#searchBox > ul").css({maxHeight: "calc(100vh - " + negativePx + "px)"})
    }
    const changeStateVisibility = () => {
        if (type === null) {
            if ($("#searchBox").hasClass("visible")) {
                $("#searchBox").addClass("hidden").removeClass("visible")
            }
        }
        else {
            initList()

            $("#searchBox").addClass("visible").removeClass("hidden")

            switch (type) {
                case "keySettings":
                case "settings":
                    $("#searchBox").removeClass("overflowHidden")
                    break
                default:
                    $("#searchBox").addClass("overflowHidden")
                    break
            }

            resize()
        }

        setCatalogId(listCatalogs.length > 0 ? listCatalogs[0].id : null)
        setCategoryId(null)
        setInput("")
        setInEditKey(false)
    }
    const buildTitle = () => {
        switch (type) {
            case "settings": return "Réglages de l'écran"
            case "TSettings": return "Réglages zone haute"
            case "RSettings": return "Réglages zone des catégories"
            case "MSettings": return "Réglages première zone basse"
            case "SSettings": return "Réglages deuxième zone basse"
            case "keySettings": return "Réglages de la touche"
            default: return "Réglages"
        }
    }
    const clickOnClose = () => {
        if (inEditKey) {
            launchWizzAlert()
            return
        }

        close()
    }
    const clickOnAccess = () => {
        setObjectSheetOpened(getTargetSourceFromKey())
    }
    const buildListCatalogs = () => {
        let list = [];

        for (let i in catalogs) {
            list.push({
                value: catalogs[i].name,
                id: catalogs[i].id,
                object: catalogs[i]
            });
        }

        setListCatalogs(list);

        if (list.length > 0) {
            setCatalogId(list[0].id);
        }
    }
    const buildListCategories = () => {
        let list = [
            {
                value: "Toutes les catégories",
                id: null
            },
            {
                value: "----------",
                id: null
            }
        ]
        let id = 1
        let filtered = categories

        if (catalogId !== null) {
            filtered = filtered.filter(_ => _.catalog_id === catalogId)
        }

        for (let i in filtered) {
            list.push({
                value: ((catalogs.length > 1 && catalogId === null) ? "(" + filtered[i].catalog_name + ") " : "") + filtered[i].name,
                id: id++,
                type: "categories",
                object: filtered[i]
            })

            for (let j in filtered[i].subCategories)
                list.push({
                    value: "--- " + ((catalogs.length > 1 && catalogId === null) ? "(" + filtered[i].catalog_name + ") " : "") + filtered[i].subCategories[j].name,
                    id: id++,
                    type: "subCategories",
                    object: filtered[i].subCategories[j]
                })
        }

        setListCategories(list)
    }
    const initList = () => {
        switch (type) {
            case "categories":
                setList(categories)
                setListFiltered(categories)
                break
            case "products":
                setList(products)
                setListFiltered(products)
                break
            case "pricelists":
                setList(pricelists)
                setListFiltered(pricelists)
                break
            case "sellers":
                setList(sellers)
                setListFiltered(sellers)
                break
            case "paymentMethods":
                setList(paymentMethods)
                setListFiltered(paymentMethods)
                break
            case "functions":
                setList(functions)
                setListFiltered(functions)
                break
            case "settings":

                break
            case "keySettings":

                break
            default: break
        }
    }
    const filterList = () => {
        let filtered = list

        switch (type) {
            case "categories":
            case "pricelists":
                if (catalogId !== null) {
                    filtered = filtered.filter(_ => _.catalog_id === catalogId)
                }

                if (input.length > 0) {
                    filtered = filtered.filter(_ => _.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                }

                break
            case "products":
                if (catalogId !== null) {
                    filtered = filtered.filter(_ => _.catalog_id === catalogId)
                }

                if (categoryId !== null) {
                    let categorySelected = listCategories.find(_ => _.id === categoryId)
                    filtered = filtered.filter(_ => _.category_type === categorySelected.type && _.category_id === categorySelected.object.id)
                }

                if (input.length > 0) {
                    filtered = filtered.filter(_ => _.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                }

                break
            case "paymentMethods":
                if (input.length > 0) {
                    filtered = filtered.filter(_ => _.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                }

                break
            case "sellers":
                if (input.length > 0) {
                    filtered = filtered.filter(_ => _.firstname.toLocaleLowerCase().includes(input.toLocaleLowerCase()) || _.lastname.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                }

                break
            case "functions":
                if (input.length > 0) {
                    filtered = filtered.filter(_ => _.code === parseInt(input) || _.label.toLocaleLowerCase().includes(input.toLocaleLowerCase()))
                }

                break
            default: break
        }

        setListFiltered(filtered)
    }
    const getTargetSourceFromKey = () => {
        if (keyPress === null) return null

        let source = null

        switch (keyPress.action_code) {
            case 101: // pricelist
                source = pricelists.find(_ => _.id === keyPress.target_id) ?? null
                break
            case 102: // seller
                source = sellers.find(_ => _.id === keyPress.target_id) ?? null
                break
            case 306: // payment method
                source = paymentMethods.find(_ => _.id === keyPress.target_id) ?? null
                break
            case 501: // category
                let keyboard = keyboards.find(_ => _.id === keyPress.target_id) ?? null
                if (keyboard === null) return

                source = categories.find(_ => _.id === keyboard.keyboardable_id) ?? null
                break
            case 504: // sub category
                for (let i in categories) {
                    for (let j in categories[i].subCategories) {
                        if (categories[i].subCategories[j].id === keyPress.target_id) {
                            source = categories[i].subCategories[j]
                            break
                        }
                    }

                    if (source !== null) break
                }

                break
            case 701: // product
            case 702:
                source = products.find(_ => _.id === keyPress.target_id) ?? null
                break
            default: break
        }

        return source
    }
    const backAction = type => {
        if (inEditKey) {
            launchWizzAlert()
            return
        }

        action(type)
    }
    const buildAccess = () => {
        if (type !== "keySettings") return

        if (getTargetSourceFromKey() !== null)
            return <LinkAccessIcon classname={"access"} action={clickOnAccess} />
    }
    const displayFilteredList = () => {
        switch (type) {
            case "categories":
                let extendedList = [];

                for (let i in listFiltered) {
                    extendedList.push(listFiltered[i]);

                    if (listFiltered[i].subCategories !== undefined && listFiltered[i].subCategories.length > 0) {
                        for (let j in listFiltered[i].subCategories) {
                            extendedList.push(listFiltered[i].subCategories[j]);
                        }
                    }
                }

                return <ul>
                    { extendedList.map((item, index) => buildListItem(item, index)) }
                    <li className={"custom"} onMouseDown={(e) => { takeItem(e, null, true) }}><p className={"name"}>Catégorie personnalisée</p></li>
                </ul>;
            default:
                return <ul>
                    { listFiltered.map((item, index) => buildListItem(item, index)) }
                </ul>;
        }
    }
    const buildListItem = (item, index) => {
        let keyboarded = false
        let keyboard

        switch (type) {
            case "categories":
                if (item.class_type === "Category") {
                    keyboard = keyboards.find(_ => _.keyboardable_type === "categories" && _.keyboardable_id === item.id)

                    if (keyboard !== undefined && listKeys.find(_ => _.action_code === 501 && _.target_id === keyboard.id) !== undefined)
                        keyboarded = true
                }
                else if (item.class_type === "SubCategory") {
                    if (listKeys.find(_ => _.action_code === 504 && _.target_id === item.id) !== undefined)
                        keyboarded = true
                }

                break
            case "pricelists":
                if (listKeys.find(_ => _.action_code === 101 && _.target_id === item.id) !== undefined)
                    keyboarded = true

                break
            case "products":
                keyboard = keyboards.find(_ => _.keyboardable_type === item.category_type && _.keyboardable_id === item.category_id)

                if (keyboard !== undefined && listKeys.find(_ => _.action_code === 501 && _.target_id === keyboard.id) !== undefined)
                    keyboarded = true
                else if (listKeys.find(_ => [701, 702].includes(_.action_code) && _.target_id === item.id) !== undefined)
                    keyboarded = true

                break
            case "paymentMethods":
                if (listKeys.find(_ => _.action_code === 306 && _.target_id === item.id) !== undefined)
                    keyboarded = true

                break
            case "sellers":
                if (listKeys.find(_ => _.action_code === 102 && _.target_id === item.id) !== undefined)
                    keyboarded = true

                break
            case "functions":
                if (listKeys.find(_ => _.action_code === item.code) !== undefined)
                    keyboarded = true

                break
            default: break
        }

        return <li key={index} className={(keyboarded ? "keyboarded": "")} onMouseDown={(e) => { takeItem(e, item) }}>
                { buildName(item) }
        </li>;
    }
    const buildListItemIcon = item => {
        if (item.class_type === undefined)
            return <></>;

        if (item.class_type === "Category")
            return <CategoryIcon />;
        else if (item.class_type === "SubCategory")
            return <SubCategoryIcon />;
        else
            return <></>;
    }
    const buildName = item => {
        switch (type) {
            case "categories":
                 return <div className={"name container" + ((item.class_type !== undefined && item.class_type === "SubCategory") ? " indent" : "")}>
                     { buildListItemIcon(item) }
                     <p>{ returnName(item) }</p>
                 </div>;
            case "pricelists":
                if (catalogs.length > 1 && catalogId === null) {
                    return <>
                        <p className={"details"}>{ item.catalog_name }</p>
                        <p className={"name"}>{ returnName(item) }</p>
                    </>;
                } else
                    return <p className={"name"}>{ returnName(item) }</p>;
            case "products":
                if (((catalogs.length > 1 && catalogId === null) || categoryId === null)) {
                    return <>
                        <p className={"details"}>
                            {catalogs.length > 1 ? <>
                                <p className={"catalog"}>{item.catalog_name}</p>
                                <p className={"category"}>{item.category_name}</p>
                            </> : item.category_name

                            }
                        </p>
                        <p className={"name"}>{ returnName(item) }</p>
                    </>
                } else {
                    return <p className={"name"}>{ returnName(item) }</p>
                }
            case "paymentMethods":
                return <p className={"name"}>{ returnName(item) }</p>
            case "sellers":
                return <p className={"name"}>{ returnName(item) }</p>
            case "functions":
                return <>
                    <p className={"code"}>{ item.code }</p>
                    <p className={"label"}>{ returnName(item) }</p>
                </>
            default:
                break
        }
    }
    const buildForm = () => {
        switch (type) {
            case "settings":
                let zones = [
                    {
                        title: "Zone haute",
                        ident: "T"
                    },
                    {
                        title: "Zone des catégories",
                        ident: "R"
                    },
                    {
                        title: "Première zone basse",
                        ident: "M"
                    },
                    {
                        title: "Deuxième zone basse",
                        ident: "S"
                    }
                ]

                return <div className={"scroller"}>
                    <div className={"containerZoneAccess"}>
                        {
                            zones.map((zone, index) => (
                                <div key={ index } className={"itemZone"} onClick={() => { actionZone(zone.ident) }} onMouseOver={() => { overZone(zone.ident, true) }} onMouseLeave={() => { overZone(zone.ident, false) }}>
                                    <p>{zone.title}</p>
                                    <RightIcon />
                                </div>
                            ))
                        }
                    </div>
                    <FormScreenObject />
                </div>
            case "TSettings":
            case "RSettings":
            case "SSettings":
            case "MSettings":
                return <FormKeyboardObject zone={type.replace("Settings", "")} />
            case "keySettings":
                return <FormKeyObject />
            default: break
        }
    }
    const overZone = (zone, over) => {
        if (over)
            $("#keyboard-" + zone + " .highlightedBg").addClass("visible")
        else
            $("#keyboard-" + zone + " .highlightedBg").removeClass("visible")
    }
    const actionZone = zone => {
        $("#keyboard-" + zone + " .highlightedBg").removeClass("visible")
        action(zone + "Settings")
    }
    const changeInput = event => {
        setInput(event.currentTarget.value)
    }
    const changeCatalog = event => {
        let value = parseInt(event.currentTarget.value)
        setCatalogId(isNaN(value) ? null : value)
    }
    const changeCategory = event => {
        let value = parseInt(event.currentTarget.value)
        setCategoryId(isNaN(value) ? null : value)
    }
    const launchWizzAlert = () => {
        $("#searchBox .savingBar").addClass("wizz")

        setTimeout(() => {
            $("#searchBox .savingBar").removeClass("wizz")
        }, 1200)
    }

    useEffect(() => {
        window.addEventListener('resize', resize, false)

        buildListCatalogs()

        return () => {
            window.removeEventListener('resize', resize, false)
        }
    }, [])
    useEffect(() => {
        buildListCategories()
    }, [categories])
    useEffect(() => {
        changeStateVisibility()
    }, [type])
    useEffect(() => {
        if (prevInput === undefined) return

        const timeoutInput = setTimeout(() => {
            filterList()
        }, 1000)

        return () => clearTimeout(timeoutInput)
    }, [input])
    useEffect(() => {
        buildListCategories()
    }, [catalogId])
    useEffect(() => {
        filterList()
    }, [catalogId, categoryId])

    return(
        <div id={"searchBox"} className={"searchBox"}>
            <div className={"searchBar"}>
                {
                    listByTypes.includes(type)
                        ? <input className={"text"} placeholder={"Rechercher"} value={input} onChange={changeInput}/>
                        : <div className={"containerTitle"}>
                            {
                                listByZoneSettings.includes(type)
                                && <LeftIcon classname={"backLink"} action={() => { backAction("settings") }} />
                            }
                            <p className={"title"}>{ buildTitle() }</p>
                        </div>
                }
                <div className={"containerIcons"}>
                    {
                        buildAccess()
                    }
                    <CloseIcon classname={"close"} onclick={clickOnClose} />
                </div>
            </div>
            {
                (catalogs.length > 1 && ['categories', 'products', 'pricelists'].includes(type))
                && <div className={"searchBar noMarginTop"}>
                    <select value={catalogId !== null ? catalogId : 0} onChange={changeCatalog}>
                    {
                        listCatalogs.map((catalog, index) => (
                            <option key={index} value={catalog.id}>{catalog.value}</option>
                        ))
                    }
                </select>
                </div>
            }
            {
                type === "products"
                && <div className={"searchBar noMarginTop"}>
                    <select value={categoryId !== null ? categoryId : 0} onChange={changeCategory}>
                        {
                            listCategories.map((category, index) => (
                                <option key={index} value={category.id}>{category.value}</option>
                            ))
                        }
                    </select>
                </div>
            }
            {
                listByTypes.includes(type)
                    ? displayFilteredList()
                    : buildForm()
            }
        </div>
    )
}

export default SearchBox;
