import $ from "jquery"
import { v4 as uuidv4 } from 'uuid'
import Key from "../../stories/_setting/Keys/Key"
import Color from "./Color"
import CustomKey from "../../stories/_setting/CustomKeys/CustomKey"
import SubCategory from "../../stories/_catalog/SubCategories/SubCategory"
import Product from "../../stories/_catalog/Products/Product"
import Pricelist from "../../stories/_catalog/Pricelists/Pricelist"
import Seller from "../../stories/_setting/Sellers/Seller"
import PaymentMethod from "../../stories/_setting/PaymentMethods/PaymentMethod"
import Action from "../../stories/_setting/Actions/Action"
import Category from "../../stories/_catalog/Categories/Category"

class Keys {
    static preview(categories, item, pKey, pKeyboard, categoryKeySelected = null, constructorNameOrigin = null, newPlace = false) {
        let keyJquery = pKey
        let width = $(keyJquery).width()
        let height = $(keyJquery).height()
        let icon = null
        let label = ""
        let bgColor = "#555555"
        let colorFound = false
        let constructorName = Keys.returnConstructorName(item)
        let key

        if (newPlace) {
            key = new Key(Object.assign({}, item))
            key.keyboard_id = pKeyboard.id
            key.rank = parseInt($(keyJquery).find(".rank").text())

            icon = key.image
            label = key.label
            bgColor = key.color
        }
        else {
            key = new Key({
                uuid: uuidv4(),
                keyboard_id: pKeyboard.id,
                target_id: item.id,
                target_object: item,
                rank: parseInt($(keyJquery).find(".rank").text())
            })

            switch (constructorName) {
                case "CustomKey":
                    key.action_code = 500
                    key.label = "Catégorie"
                    label = key.label

                    break
                case "Category":
                    if (constructorNameOrigin === "Key") {
                        key.action_code = 501
                    }

                    key.label = item.name
                    label = item.name

                    if (item.color !== undefined && item.color !== null && item.color.length > 0)
                        bgColor = item.color

                    break
                case "SubCategory":
                    key.action_code = 504
                    key.label = item.name
                    label = item.name

                    if (item.color !== undefined && item.color !== null && item.color.length > 0)
                        bgColor = item.color
                    else {
                        let category = categories.find(_ => _.id === item.category_id)

                        if (category !== undefined && category.color !== undefined && category.color !== null && category.color.length > 0)
                            bgColor = category.color
                    }

                    break
                case "Product":
                    key.action_code = 701
                    key.label = item.label
                    label = item.label

                    let category = undefined;

                    if (item.category_type === "categories")
                        category = categories.find(_ => _.id === item.category_id)
                    else {
                        for (let i in categories) {
                            category = categories[i].subCategories.find(_ => _.id === item.category_id)

                            if (category !== undefined)
                                break;
                        }
                    }

                    if (category.color !== undefined && category.color !== null && category.color.length > 0) {
                        bgColor = category.color
                        colorFound = true
                    }
                    else {
                        if (!["Category", "Categories"].includes(item.category_type)) {
                            category = categories.find(_ => _.id === category.category_id)

                            if (category !== undefined && category.color !== undefined && category.color !== null && category.color.length > 0) {
                                bgColor = category.color
                                colorFound = true
                            }
                        }
                    }

                    if (!colorFound && categoryKeySelected !== null) {
                        // get categoryKey (right keyboard) color
                        bgColor = categoryKeySelected.color
                    }

                    break
                case "Pricelist":
                    key.action_code = 101
                    key.label = item.name
                    label = item.name
                    bgColor = item.color
                    break
                case "Seller":
                    key.action_code = 102
                    key.label = item.fullnameLimited
                    label = item.fullnameLimited
                    break
                case "PaymentMethod":
                    key.action_code = 306
                    key.label = item.name
                    label = item.name

                    switch (item.predefined_payment_method_id) {
                        case 3:
                            icon = "payment_1"
                            break
                        case 4:
                            icon = "payment_6"
                            break
                        case 5:
                        case 6:
                            icon = "payment_9"
                            break
                        case 7:
                            icon = "payment_22"
                            break
                        default: break
                    }

                    break
                case "Action":
                    let defaultIcons = {
                        1: "1_g",
                        2: "2_fg",
                        4: "4_c",
                        7: "7_b",
                        8: "8_o",
                        201: "201_b",
                        202: "202_b",
                        203: "203_b",
                        204: "204_r",
                        205: "205_o",
                        206: "206_o",
                        207: "207_bc",
                        301: "301_b",
                        304: "304_r",
                        305: "305_b",
                        307: "307_bc",
                        308: "308_b",
                        310: "310_o",
                        401: "401_b",
                        402: "402_b",
                        801: "801_b",
                        950: "950_v",
                        951: "951_r"
                    }
                    label = (item.keyLabel !== null && item.keyLabel.length > 0) ? item.keyLabel : item.label
                    key.action_code = item.code
                    key.label = label

                    if (defaultIcons[item.code] !== undefined)
                        icon = defaultIcons[item.code]

                    break
                default: break
            }

            key.color = bgColor

            if (icon !== null)
                key.image = icon
        }

        if (bgColor.length === 0)
            bgColor = "#555555"

        let backgroundColor = bgColor
        let color = Color.shadeColor(bgColor, -30)

        $(keyJquery).removeClass("highlighted")
        $(keyJquery).addClass("actionnable")

        $(keyJquery).find(".label").css({color: color})
        $(keyJquery).find(".label").text(label)
        $(keyJquery).find(".radiusBg").css({backgroundImage: "linear-gradient(" + backgroundColor + " , " + backgroundColor + ")"})
        $(keyJquery).find(".radiusBg").animate(
            {
                width: (width > height ? 200 : (200 * (height / width))) + "%",
                height: (height > width ? 200 : (200 * (width / height))) + "%"
            },
            300
        )

        return key
    }
    static returnConstructorName(item) {
        if (item instanceof CustomKey)
            return "CustomKey"
        else if (item instanceof Category)
            return "Category"
        else if (item instanceof SubCategory)
            return "SubCategory"
        else if (item instanceof Product)
            return "Product"
        else if (item instanceof Pricelist)
            return "Pricelist"
        else if (item instanceof Seller)
            return "Seller"
        else if (item instanceof PaymentMethod)
            return "PaymentMethod"
        else if (item instanceof Action)
            return "Action"
        else if (item instanceof Key)
            return "Key"
        else
            return undefined
    }
    static reRank(direction, keyboard, keys) {
        let minRank, maxRank, indexKey
        let updatedKeys = []

        if (direction < 0) {
            for (let i = 1; i <= keyboard.nbLines; i++) {
                if (i === 1) continue // ignore first line

                minRank = ((i - 1) * keyboard.nbColumns) + 1
                maxRank = (minRank + keyboard.nbColumns) - 1

                for (let j = minRank; j <= maxRank; j++) {
                    indexKey = keyboard.keys.findIndex(_ => _.rank === j)
                    if (indexKey < 0) continue

                    keyboard.keys[indexKey].rank -= (i - 1)
                    updatedKeys.push({ id: keyboard.keys[indexKey].id, keyboardId: keyboard.id, rank: keyboard.keys[indexKey].rank })
                }
            }
        }
        else {
            for (let i = keyboard.nbLines; i >= 1; i--) {
                if (i === 1) continue // ignore first line

                minRank = ((i - 1) * keyboard.nbColumns) + 1
                maxRank = (minRank + keyboard.nbColumns) - 1

                for (let j = maxRank; j >= minRank; j--) {
                    indexKey = keyboard.keys.findIndex(_ => _.rank === j)
                    if (indexKey < 0) continue

                    keyboard.keys[indexKey].rank += (i - 1)
                    updatedKeys.push({ id: keyboard.keys[indexKey].id, keyboardId: keyboard.id, rank: keyboard.keys[indexKey].rank })
                }
            }
        }

        return {
            keyboard: keyboard,
            keys: keys,
            updatedKeys: updatedKeys
        }
    }
}

export default Keys
