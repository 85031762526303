import React, {useContext, useEffect, useState} from "react"
import { ReactSVG } from 'react-svg'
import $ from "jquery"
import NumberFormat from "react-number-format"
import Color from "../../../../class/tool/Color"
import ScreenContext from "../../../../context/ScreenContext"

const KeyComponent = props => {
    const {keyboard, object, handleOpenAction} = props
    const {categoryKeySelected, products, takeItem, releaseItemClearTake, fontSizeRatio} = useContext(ScreenContext)
    const [reRender, setRender] = useState(false)

    const init = () => {
        setRender(true)
    }
    const action = event => {
        if (object === null || object.action_code === 0) return

        if ((keyboard.type !== "P" && object.action_code !== 0) || (keyboard.type === "P" && categoryKeySelected !== null && categoryKeySelected.action_code !== 501 && object.action_code !== 0))
            handleOpenAction(object)
    }
    const getColor = () => {
        if (object.action_code === 0)
            return null

        if (['M', 'S'].includes(keyboard.zone))
            return "#555555";

        if (object.color !== null && object.color !== "") { // key color
            return object.color
        }
        else if (keyboard.color !== null && keyboard.color !== "" && keyboard.color.includes("#")) { // keyboard color
            return keyboard.color
        }
        else { // default color
            return "#555555"
        }
    }
    const getTextColor = () => {
        let color = getColor()

        if (color !== null) {
            return Color.shadeColor(color, -30)
        }
        else {
            return "#555555"
        }
    }
    const getFontSize = () => {
        if (keyboard.fontSize !== null)
            return keyboard.fontSize * fontSizeRatio
        else
            return 9 * fontSizeRatio
    }
    const buildIcon = () => {
        if (object.action_code === 504) {
            return <ReactSVG
                src={getResource()}
                beforeInjection={(svg) => {
                    svg.setAttribute('fill', (getColor() !== null ? getColor() : "#464646"))
                    $(svg).find("path").attr('fill', (getColor() !== null ? getColor() : "#464646"))
                }}
                className={"iconSubCategory"} />
        }
        else {
            if (object.image === null || object.image.length === 0)
                return;

            return <ReactSVG
                src={getResource()}
                beforeInjection={(svg) => {
                    if (keyboard.zone !== "R") return

                    svg.setAttribute('fill', (getColor() !== null ? getColor() : "#464646"))
                    $(svg).find("path").attr('fill', (getColor() !== null ? getColor() : "#464646"))
                }}
                className={"icon"} />
        }
    }
    const getResource = () => {
        let imgSrc = object.action_code === 504 ? "sub_category" : object.image;

        if (imgSrc.includes(".svg"))
            imgSrc = imgSrc.replace(".svg", "");

        try {
            return require("../../../../image/ressource/library/" + imgSrc + ".svg");
        }
        catch {
            return "";
        }
    }
    const buildLabel = () => {
        let labelShowing = true
        let priceShowing = true
        let stockShowing = false
        let price = null

        if (object.labelShowing !== null)
            labelShowing = object.labelShowing === 1
        else if (keyboard.labelShowing !== null)
            labelShowing = keyboard.labelShowing === 1

        if (keyboard.priceShowing !== null)
            priceShowing = keyboard.priceShowing === 1

        if (keyboard.stockShowing !== null)
            stockShowing = keyboard.stockShowing === 1

        if (priceShowing && object.action_code === 701) {
            let product = products.find(_ => _.id === object.target_id)

            if (product !== undefined) {
                price = product.price
            }
        }

        if (price === null)
            priceShowing = false

        return <div className={ "contentInfos" + ((object.image !== null && object.image !== "") ? " withIcon" : "") + (((priceShowing || stockShowing) && keyboard.zone === "P") ? " complex" : "") }>
            {
                labelShowing
                && <p className={"label"}
                   style={
                       {
                           fontSize: getFontSize() + "pt",
                           color: getTextColor()
                       }
                   }>
                    { object.label }
                </p>
            }
            {
                (priceShowing || stockShowing)
                && <div className={"contentPriceStock"}>
                    {
                        priceShowing
                        && <p className="price"
                              style={
                                  {
                                      fontSize: getFontSize() + "pt",
                                      color: getTextColor()
                                  }
                              }>
                            <NumberFormat value={price} decimalScale={2} thousandSeparator={' '} decimalSeparator={','} fixedDecimalScale={true} displayType={'text'} />
                        </p>
                    }
                    {
                        stockShowing
                        && <p className="stock"
                              style={
                                  {
                                      fontSize: getFontSize() + "pt",
                                      color: getTextColor()
                                  }
                              }>
                            0
                        </p>
                    }
                </div>
            }
        </div>
    }
    const buildKey = () => {
        return <div className={"wrap"}>
            { buildIcon() }
            { buildLabel() }
        </div>
    }
    const initRadiusBg = () => {
        let idDom = "#keyboard-" + keyboard.zone + "-key-" + object.rank

        if (object.action_code === 0) {
            $(idDom).find(".radiusBg").css({width: 0, height: 0})
            return
        }

        let backgroundColor = getColor()
        let width = $(idDom).width()
        let height = $(idDom).height()

        if (backgroundColor === null)
            backgroundColor = "#555555"

        $(idDom).find(".radiusBg").css({backgroundImage: "linear-gradient(" + backgroundColor + " , " + backgroundColor + ")", width: (width > height ? 200 : (200 * (height / width))) + "%", height: (height > width ? 200 : (200 * (width / height))) + "%"})
        $(idDom).find(".layer").css({display: "none"})
    }

    useEffect(() => {
        init()
    } ,[]);

    return (
        <div id={"keyboard-" + keyboard.zone + "-key-" + object.rank}
             className={"key" + ((keyboard.type === "P" && categoryKeySelected !== null && categoryKeySelected.action_code === 501 && object.action_code === 0) ? " noVisible" : "") + (((keyboard.type !== "P" && object.action_code !== 0) || (keyboard.type === "P" && categoryKeySelected !== null && categoryKeySelected.action_code !== 501 && object.action_code !== 0)) ? " actionnable" : "")}
             onMouseDown={(e) => { takeItem(e, object) }}
             onMouseUp={(e) => { releaseItemClearTake(e) }}
             onClick={action}
             style={(object.action_code > 0 ? {backgroundColor: "#FFFFFF"} : {})}>
            <span className={"rank"} style={{display: "none"}}>{object.rank}</span>
            <div className={"layer"} style={getColor() !== null ? {backgroundColor: getColor()} : {}}/>
            <span className={"radiusBg"}/>
            {buildKey()}
        </div>
    )
}

export default KeyComponent;
